<template>
  <div class="sold-by-component-container">
    <div v-if="user">
      <!--<router-link :to="{ name: 'Seller', params: { userId: user.firestoreId }}">-->

      <div class="seller-container">
        <h6 class="seller-profile">
          <div class="avatarAndBadgeContainer">
            <b-avatar
              variant="secondary"
              v-if="!profilePhoto"
              size="4rem"
            ></b-avatar>
            <b-avatar
              variant="light"
              :src="profilePhoto"
              v-if="profilePhoto"
              size="4rem"
            ></b-avatar>
            <b-badge v-if="user.pro == true" pill variant="primary" class="mt-1"
              >PRO</b-badge
            >
          </div>
        </h6>
        <div class="seller-informations">
          <h4>{{ getUserName() }}</h4>
          <p v-if="user.pro == true">N° SIRET {{ user.siret }}</p>
          <!--<p class="annoncesEnLigne">{{ user.adcount }} annonces en ligne</p>-->
        </div>
      </div>
      <div
        v-if="templateMode == 1"
        class="additionalButtonsContainer"
        style="margin-bottom: 0px"
      >
        <!-- <b-button v-if="!amoi && (tel == undefined)" @click="clickContactPhone()" class="additionalButtons" type="submit" variant="primary">Contacter</b-button>-->
        <div v-if="!amoi && tel != undefined" class="telephoneDisplay">
          Tel : {{ tel }}
        </div>

        <b-button
          v-if="!amoi"
          @click="clickContactEmail()"
          @mouseover="messageDisplayed = true"
          @mouseleave="messageDisplayed = false"
          class="additionalButtons message"
          type="submit"
          variant="primary"
          style="display: flex; justify-content: center"
          >Message</b-button
        >
        <b-button
          v-if="amoi"
          @click="supprimerAnnonce()"
          class="additionalButtons"
          type="submit"
          variant="primary"
          >Supprimer</b-button
        >
      </div>
      <!-- </router-link>-->
    </div>
  </div>
</template>

<script>
import AuthService from "../../../services/AuthService.js";
import StorageService from "../../../services/StorageService.js";

export default {
  name: "SoldByComponent",
  components: {},
  props: ["user", "templateMode", "tel"],
  data: () => {
    return {
      profilePhoto: undefined,
      amoi: false,
      messageDisplayed: false,
    };
  },

  methods: {
    loadPhoto() {
      if (this.user && this.user.photo) {
        StorageService.getProfilePhotoDownloadURL(this.user).then((url) => {
          this.profilePhoto = url;
        });
      } else {
        console.info("this.user = " + this.user);
        console.info("this.user.photo = " + this.user.photo);
      }
    },

    getUserName() {
      this.loadPhoto();

      if (
        AuthService.isLogged() &&
        AuthService.getCurrentUserId() == this.user.firestoreId
      ) {
        this.amoi = true;
        return "Moi (" + this.user.nom + ")";
      }
      return this.user.nom;
    },
    clickContactPhone() {
      this.$root.$emit("contact_action", 1);
    },
    clickContactEmail() {
      this.$root.$emit("contact_action", 2);
    },
    supprimerAnnonce() {
      console.info("supprimerAnnonce()");
      this.$root.$emit("contact_action", 3);
    },
  },
};
</script>

<style lang="scss">
@import "sold-by-component.scss";
</style>