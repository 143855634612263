<script>
import SoldByComponent from '../../shared-component/sold-by-component/sold-by-component.vue'
import AdComponent from '../../shared-component/ad-component/ad-component.vue'

import {ToastMixin} from '../../../mixins/toast-mixin.js'
import {HandleFavoriteMixin} from '../../../mixins/handle-favorite-mixin.js'
import AnnoncesService from '../../../services/AnnoncesService.js'
import UsersService from '../../../services/UsersService.js'

export default {
    name: "SellerProfilePage",
    components: {
        SoldByComponent,
        AdComponent
    },
    props: ['userId'],
    mixins: [ToastMixin, HandleFavoriteMixin],
    data: () => {
        return {
            collapseVisible: false,
            itemsList : [],
            user: undefined,
        }
    },
     created(){
         this.$emit('headerState', 2);
        UsersService.getSingleUser(this.userId).then((user)=>{
                this.user = user;
                console.info("Recuperation info utilisateur effectuee : " + user.nom)
        });

        AnnoncesService.getAllUserAnnonces(this.userId).then((annoncesArray)=>{
                this.itemsList = annoncesArray;
        });
    },
};
</script>

<style lang="scss">
    @import "seller-profile-page.scss";
</style>


<template src="./seller-profile-page.html"></template>