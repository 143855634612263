<template>
    <div class="ad-component-container">
        <router-link :to="{ name: 'Ad', params: { adId: annonce.firestoreId }}">
            <b-card :img-src="ad_image" img-alt="Card image" img-left class="mt-2">
                <b-card-text>
                    <h6>{{ annonce.titre }}</h6>
                        <div class="location-date-container">
                            <div class="location">{{ getDepartementByCode(annonce.dept).text }}, {{ getRegionByCode(annonce.region).text }}</div>
                            <div class="date">Publié le <time datetime="2018-07-07">{{ annonce.date | formatDate}}</time></div>
                        </div>
                        <div class="price-like-container">
                            <div class="pink-price">{{ annonce.prix }}€</div>
                            <!--
                            <template v-if="favorite != undefined">
                                <img @click="favouriteSwitch($event, favorite)" v-if="!favorite" src="../../../assets/icons/0141-heart.svg" alt="coeur remplis">
                                <img @click="favouriteSwitch($event, favorite)" v-else src="../../../assets/icons/0141-heart-full.svg" alt="coeur vide">
                            </template>-->
                        </div>
                </b-card-text>
            </b-card>
        </router-link>
    </div>
</template>

<script>

import StorageService from '../../../services/StorageService.js'
import FavoritesService from '../../../services/FavoritesService.js'
import AuthService from '../../../services/AuthService.js'
import Const from "../../../model/Const.js"

export default {
    name: "AdComponent",
    props: ['dept','region', 'price','annonce'],

    data() {
        return { 
            favorite:undefined,
            ad_image:require(`../../../assets/new_logo.jpeg`),
        }
    },

    methods: {
        // Switch for favorite / unfavorite ad
        favouriteSwitch(event, favorite) {
            if (!favorite){
                FavoritesService.addToFavorite(this.annonce).then(()=> this.updateFavoriteState());
            } else {
                FavoritesService.deleteFavorite(this.annonce).then(()=> this.updateFavoriteState());
            }

            favorite = !favorite
            console.log('fav in ad', favorite)
            console.log('ad index in ad', this.annonce.firestoreId)
       

            // Cancel the clickable link for ad
            event.preventDefault()
            this.$emit('favoriteState', favorite, this.annonce.firestoreId)
        },
         getDepartementByCode(code){
            return Const.getDepartementByCode(code);
        },
        getRegionByCode(code){
            return Const.getRegionByCode(code);
        },
         updateFavoriteState(){
            FavoritesService.isFavorite(this.annonce).then((b)=>{
                this.favorite = b;
            },() =>{
                this.favorite = undefined;
            });

         },
    },
   
    created(){
        if (this.annonce.photo1){
             StorageService.getDownloadURL(this.annonce,1).then((url)=>{
                this.ad_image = url;
                console.info("ad_image = " + this.ad_image);
            });
        }
        if (!AuthService.isLogged() || AuthService.getCurrentUserId() == this.annonce.userId){
            this.favorite = undefined;
        } else {
            //this.updateFavoriteState();
        }
         

    },

    
};
</script>

<style lang="scss">
    @import "ad-component.scss";
</style>